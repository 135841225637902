<template>
  <div>
    <div class="grid crud-demo">
      <h6 class="">Administração de Projetos</h6>
      <Toast/>

      <div class="col-12">
        <div class="card widget-boxes  p-0 grid grid-nogutter">
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-shopping-cart" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Projetos</span>
            </div>
            <span class="block font-bold text-6xl mb-3">1420</span>
            <ProgressBar :value="50" :showValue="false" class="mb-3"></ProgressBar>
          </div>
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Dentro do Prazo</span>
            </div>
            <span class="block font-bold text-6xl mb-3">701</span>
            <ProgressBar :value="60" :showValue="false" class="mb-3"></ProgressBar>
          </div>
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-users" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Finalizado</span>
            </div>
            <span class="block font-bold text-6xl mb-3">21</span>
            <ProgressBar :value="80" :showValue="false" class="mb-3"></ProgressBar>
          </div>
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6">
            <div class="mb-3">
              <Avatar icon="pi pi-comments" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Parado 7 Dias</span>
            </div>
            <span class="block font-bold text-6xl mb-3">85</span>
            <ProgressBar :value="30" :showValue="false" class="mb-3"></ProgressBar>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">

          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                <!--              <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProjetos || !selectedProjetos.length" />-->
              </div>
            </template>

            <template v-slot:end>
              <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" />
              <Button label="Exportar" icon="pi pi-download" class="p-button-outlined" @click="exportCSV($event)"  />
            </template>
          </Toolbar>

          <DataTable ref="dt" :value="projetos" v-model:selection="selectedProjetos" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orçamentos" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
              </div>
            </template>

            <!--          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
            <Column field="id" header="Código" :sortable="true" filterField="id" :showFilterMatchModes="false" >
              <template #body="slotProps">
                <span class="p-column-title">Id</span>
                {{slotProps.data.id}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
              </template>
            </Column>
            <Column field="name" header="Cliente" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Name</span>
                {{slotProps.data.name}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo cliente"/>
              </template>
            </Column>
            <!--          <Column header="Image">-->
            <!--            <template #body="slotProps">-->
            <!--              <span class="p-column-title">Image</span>-->
            <!--              <img :src="'demo/images/projeto/' + slotProps.data.image" :alt="slotProps.data.image" class="shadow-2" width="100" />-->
            <!--            </template>-->
            <!--          </Column>-->
<!--            <Column field="cidade" header="Cidade" :sortable="true" class="text-left">-->
<!--              <template #body="slotProps">-->
<!--                <span class="p-column-title">Cidade</span>-->
<!--                {{formatCurrency(slotProps.data.cidade)}}-->
<!--              </template>-->
<!--              <template #filter="{filterModel}">-->
<!--                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela cidade"/>-->
<!--              </template>-->
<!--            </Column>-->
<!--            <Column field="estado" header="Estado" :sortable="true" class="text-right">-->
<!--              <template #body="slotProps">-->
<!--                <span class="p-column-title">Estado</span>-->
<!--                {{slotProps.data.estado}}-->
<!--              </template>-->
<!--              <template #filter="{filterModel}">-->
<!--                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela estado"/>-->
<!--              </template>-->
<!--            </Column>-->
            <Column field="category" header="Categoria" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Category</span>
                {{slotProps.data.category}}
              </template>

              <template #filter="{filterModel}">
                <div class="mb-3 text-bold">Agent Picker</div>
                <MultiSelect v-model="filterModel.value" :options="categorias" optionLabel="name" placeholder="Any" class="p-column-filter">
                  <template #option="slotProps">
                    <div class="p-multiselect-representative-option">
                      <!--                    <img :alt="slotProps.option.name" :src="'demo/images/avatar/' + slotProps.option.image" width="32" style="vertical-align: middle" />-->
                      <span style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.option.name}}</span>
                    </div>
                  </template>
                </MultiSelect>
              </template>

            </Column>

            <Column field="status" header="Status" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'projeto-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{slotProps.data.status}}</span>
              </template>
            </Column>

            <Column field="statusobra" header="Status Obra" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Status Obra</span>
                <span :class="'projeto-badge status-' + (slotProps.data.statusobra ? slotProps.data.statusobra.toLowerCase() : '')">{{slotProps.data.statusobra}}</span>
              </template>
            </Column>

            <Column header="Data" filterField="date" dataType="date" style="min-width:10rem">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
              </template>
            </Column>

            <Column field="balance" header="Valor" :sortable="true" class="text-right">
              <template #body="slotProps">
                <span class="p-column-title">Valor</span>
                {{formatCurrency(slotProps.data.balance)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>

            <Column field="potencia" header="Potência" :sortable="true" class="text-right">
              <template #body="slotProps">
                <span class="p-column-title">Potência</span>
                {{formatDecimal(slotProps.data.potencia)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>


            <Column header="Ações" class="text-right">
              <template #body="slotProps">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2 mb-2" @click="confirmDeleteProjeto(slotProps.data)" />

                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2 mb-2" @click="$router.push('projetodetalhe')" />
                <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2 mb-2" @click="visibleTimeline = true" />
              </template>
            </Column>
          </DataTable>




          <Dialog v-model:visible="projetoDialog" :style="{width: '450px'}" header="Detalhes do Orçamento" :modal="true" class="p-fluid">
            <!--          <img :src="'demo/images/projeto/' + projeto.image" :alt="projeto.image" v-if="projeto.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" />-->
            <div class="field">
              <label for="name">Cliente</label>
              <InputText id="name" v-model.trim="projeto.name" required="true" autofocus :class="{'p-invalid': submitted && !projeto.name}" />
              <small class="p-invalid" v-if="submitted && !projeto.name">Cliente é obrigatório.</small>
            </div>
            <div class="field">
              <label for="description">Descrição</label>
              <Textarea id="description" v-model="projeto.description" required="true" rows="3" cols="20" />
            </div>

            <div class="field">
              <label for="inventoryStatus" class="mb-3">Status</label>
              <Dropdown id="inventoryStatus" v-model="projeto.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Select a Status">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'projeto-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'projeto-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
            </div>

            <div class="field">
              <label class="mb-3">Categoria</label>
              <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                  <RadioButton id="category1" name="category" value="Accessories" v-model="projeto.category" />
                  <label for="category1">Grandes Negócios</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category2" name="category" value="Clothing" v-model="projeto.category" />
                  <label for="category2">Residencial</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category3" name="category" value="Electronics" v-model="projeto.category" />
                  <label for="category3">Rural</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category4" name="category" value="Fitness" v-model="projeto.category" />
                  <label for="category4">Comercial</label>
                </div>
              </div>
            </div>

            <div class="formgrid grid">
              <div class="field col">
                <label for="price">Valor</label>
                <InputNumber id="price" v-model="projeto.price" mode="currency" currency="BRL" locale="pt-BR" />
              </div>
              <div class="field col">
                <label for="quantity">Potência</label>
                <InputNumber id="quantity" v-model="projeto.quantity" integeronly />
              </div>
            </div>
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
              <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveProjeto" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteProjetoDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="projeto">Confirma a exclusão de <b>{{projeto.name}}</b>?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProjetoDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProjeto" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteProjetosDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="projeto">Tem certeza que deseja deletar os itens selecionados?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProjetosDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProjetos" />
            </template>
          </Dialog>
        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Últimos Meses</h6>
          <Chart type="bar" :data="comboData" :options="comboOptions" />

        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>Evolução Mês</h6>
          <Chart type="polarArea" :data="polarData" :options="polarOptions" />
        </div>
      </div>
    </div>


    <div class="col-12 lg:col-12">
      <Sidebar v-model:visible="visibleTimeline" :baseZIndex="1000" position="right">
        <h3 style="font-weight: normal">Timeline</h3>
        <projetos-list-timeline></projetos-list-timeline>
      </Sidebar>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import ProjetoService from '@/service/ProjetoService';
import ProjetosListTimeline from "@/components_esol/projetos/ProjetosListTimeline";


export default {
  components: {
    ProjetosListTimeline

  },
  data() {
    return {
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      projetos: null,
      projetoDialog: false,
      deleteProjetoDialog: false,
      deleteProjetosDialog: false,
      projeto: {},
      selectedProjetos: null,
      filters: {},
      submitted: false,
      statuses: [
        {label: 'QUALIFICADO', value: 'qualificado'},
        {label: 'ANÁLISE', value: 'análise'},
        {label: 'DESQUALIFICADO', value: 'desqualificado'}
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],


      barData: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'],
        datasets: [
          {
            label: 'Elaboração',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: [65, 59, 80, 81, 56, 55, 40]
          },
          {
            label: 'Convertido',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            data: [28, 48, 40, 19, 86, 27, 90]
          }
        ]
      },
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },


      radarData: {
        labels: ['GD1', 'GD0', 'MD1', 'MD0', 'PQ2', 'PQ1', 'PQ0'],
        datasets: [
          {
            label: 'ES',
            backgroundColor: 'rgba(54, 162, 235,0.2)',
            borderColor: 'rgba(54, 162, 235,1)',
            pointBackgroundColor: 'rgba(54, 162, 235,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(54, 162, 235,1)',
            data: [65, 59, 90, 81, 56, 55, 40]
          },
          {
            label: 'MG',
            backgroundColor: 'rgba(255, 99, 132,0.2)',
            borderColor: 'rgba(255, 99, 132,1)',
            pointBackgroundColor: 'rgba(255, 99, 132,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132,1)',
            data: [28, 48, 40, 19, 96, 27, 100]
          }
        ]
      },

      radarOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: 'rgba(160, 167, 181, .3)'
            }
          }
        }
      },


      pieData: {
        labels: ['GD', 'MD', 'PQ'],
        datasets: [
          {
            data: [540, 325, 702, 421],
            backgroundColor: [
              'rgb(54, 162, 235)',
              'rgb(255, 99, 132)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)'
            ]
          }]
      },
      pieOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        }
      },


      lineData: {
        labels: ['Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro'],
        datasets: [
          {
            label: 'Análise',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: 'rgb(255, 205, 86)',
            borderColor: 'rgb(255, 205, 86)',
            tension: .4
          },
          {
            label: 'Qualificado',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgb(75, 192, 192)',
            tension: .4
          }
        ]
      },
      lineOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },

      polarData: {
        datasets: [{
          data: [11,16,7,3,14],
          backgroundColor: ["#42A5F5","#66BB6A","#FFA726","#26C6DA","#7E57C2"],
          label: 'My dataset'
        }],
        labels: ["GD","MD01","MD02","PQ02","PQ01"]
      },
      polarOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: '#ebedef'
            }
          }
        }
      },



      comboData: {
        labels: ['Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro'],
        datasets: [{
          type: 'line',
          label: 'Tendência',
          borderColor: '#42A5F5',
          borderWidth: 2,
          fill: false,
          data: [50,25,12,48,56,76,42]
        }, {
          type: 'bar',
          label: 'Confirmado',
          backgroundColor: '#66BB6A',
          data: [21,84,24,75,37,65,34],
          borderColor: 'white',
          borderWidth: 2
        }, {
          type: 'bar',
          label: 'Elaboração',
          backgroundColor: '#FFA726',
          data: [41,52,24,74,23,21,32]
        }]
      },
      comboOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      }

    }
  },
  projetoService: null,
  created() {
    this.projetoService = new ProjetoService();
    this.initFilters();
  },
  mounted() {


    this.projetoService.getProjetos().then((data) => {
      console.log('projetosList mounted')
      this.loading1 = false
      this.projetos = data
      this.projetos.forEach(projeto => projeto.date = new Date(projeto.date));
    })


  },
  methods: {
    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    openNew() {
      /*
      this.projeto = {};
      this.submitted = false;
      this.projetoDialog = true;
       */

      this.$router.push({ path: '/projetodetalhe' });
    },
    hideDialog() {
      this.projetoDialog = false;
      this.submitted = false;
    },
    saveProjeto() {
      this.submitted = true;
      if (this.projeto.name.trim()) {
        if (this.projeto.id) {
          this.projeto.inventoryStatus = this.projeto.inventoryStatus.value ? this.projeto.inventoryStatus.value: this.projeto.inventoryStatus;
          this.projetos[this.findIndexById(this.projeto.id)] = this.projeto;
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamento Atualizado', life: 3000});
        }
        else {
          this.projeto.id = this.createId();
          this.projeto.code = this.createId();
          this.projeto.image = 'projeto-placeholder.svg';
          this.projeto.inventoryStatus = this.projeto.inventoryStatus ? this.projeto.inventoryStatus.value : 'ANÁLISE';
          this.projetos.push(this.projeto);
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamento Criado', life: 3000});
        }
        this.projetoDialog = false;
        this.projeto = {};
      }
    },
    editProjeto(projeto) {


      // this.projeto = {...projeto};
      // this.projetoDialog = true;

      this.$router.push({ path: `/projeto/${projeto.id}` });


    },
    confirmDeleteProjeto(projeto) {
      this.projeto = projeto;
      this.deleteProjetoDialog = true;
    },
    deleteProjeto() {
      this.projetos = this.projetos.filter(val => val.id !== this.projeto.id);
      this.deleteProjetoDialog = false;
      this.projeto = {};
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamento Deletado', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.projetos.length; i++) {
        if (this.projetos[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProjetosDialog = true;
    },
    deleteSelectedProjetos() {
      this.projetos = this.projetos.filter(val => !this.selectedProjetos.includes(val));
      this.deleteProjetosDialog = false;
      this.selectedProjetos = null;
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamentos Deletados', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'code': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},


        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'representative': {value: null, matchMode: FilterMatchMode.IN},
        'category': {value: null, matchMode: FilterMatchMode.IN},
        'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
        'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
      }

    },

    clearFilter1() {
      this.initFilters();
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
